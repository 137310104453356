import axios from "axios";
import { startLoading, endLoading } from "./api";
import { get } from 'lodash';

const interceptor = (store) => {
  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      const access_token = get(store.getState().user,'user.token');

      if(!config.url.startsWith('podcasts') && config.url.indexOf('search/filters') === -1) {
        store.dispatch(startLoading());
      }
      
      if(access_token && !config.headers.Authorization) {
        config.headers['Authorization'] = `Bearer ${access_token}`;
     }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      store.dispatch(endLoading());
      return response;
    },
    function (error) {
      store.dispatch(endLoading());
      return Promise.reject(error);
    }
  );
};

export default {
  interceptor,
};
