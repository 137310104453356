/* eslint-disable max-len */
import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import styles from "./ProfilePayments.module.scss";
import PaymentsTable from "./components/PaymentsTable/PaymentsTable";
import { usePaymentsPage, useRequestPayment } from "@podcasts/hooks";
import { Pagination } from "../../../../components";
import { userSelector } from "@podcasts/store/user";
import { useSelector } from "react-redux";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css"


const tableHeaderCol = [
  {
    name: "Date",
    filter: true,
    order: "created_at",
  },
  {
    name: "Payment Amount",
    filter: false,
  },
  {
    name: "Description",
    filter: false,
  },
  {
    name: "Learned On",
    filter: true,
  },
];

export function ProfilePayments() {
  const myRef: any = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView({ behavior: "smooth" });
  const {
    data: { data = [], last_page, current_page },
    total_amount,
    last_request_on,
    onPageChange
  } = usePaymentsPage({ per_page: 15 });
  
  const { user } = useSelector(userSelector) as any;
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");

  const { canRequestPayment } = useRequestPayment();

  return (
    <div className={`${styles["profile-payments"]}`}>
      <div className={`${styles["back-box"]}`}>
        <Link to={"/profile"}>
          <i className="icon-chevron-right"></i>
        </Link>
      </div>
      <div className={`${styles["profile-payments-top"]}`}>
        <div className={`${styles["total-box"]}`}>
          <h3>Payments</h3>
          <p>
            Total to date: <strong>${total_amount}</strong>
          </p>
        </div>
        <div className={`${styles["request-box"]} d-flex align-items-center`}>
          { last_request_on && <p>Last request on {last_request_on}</p>}
          {!!canRequestPayment && (
            <div
              className={"blue-icon-btn-wrapper d-flex justify-content-center align-items-center"}
            >
              {!user.graduated_mail_sent && (
                <Link
                  to={"../../podcasts/request/payment"}
                  className="blue-icon-btn d-flex justify-content-center align-items-center"
                  title="Request a payment"
                >
                  <span>Request a payment</span>
                  <i className="icon-payment-empty"></i>
                </Link>
              )}
            </div>
          )}
          {!!user.graduated_mail_sent &&
             (
               <>
                 <div className={`blue-icon-btn d-flex justify-content-center align-items-center ${styles["disabled"]}`} data-tooltip-id="graduated">
                   <span>Request a payment</span>
                   <i className="icon-payment-empty"></i>
                 </div>
                 <ReactTooltip id="graduated" place="bottom" border="1px solid #000000"  style={{ backgroundColor: "#fff", color: "#222", display: "flex", border: "1px solid red", fontFamily: "Sofia Pro Light Az", fontSize: "14px", fontWeight: 300, lineHeight: "16px", textAlign: "left" }}>
                   <span>Payment request is not available <br/>because you’ve graduated.</span>
                 </ReactTooltip>
               </>
             )
          }
        </div>
      </div>
      {(data && data.length) ? (
        <><PaymentsTable header={tableHeaderCol} rows={data} order={order} setOrder={setOrder} setOrderBy={setOrderBy}/>
          <Pagination
            pages={last_page}
            page={current_page}
            onPageChange={(page: number) => {
              onPageChange(page);
              executeScroll();
            }}
          />
        </>
      ) : null }
    </div>
  );
}

export default ProfilePayments;
